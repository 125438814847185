import KatalLogger, { Level, LoggerConfig } from '@amzn/katal-logger';
import { recordAWSRumEvent } from 'src/analytics/CloudWatchRumClient';
import { SpecialMetricTitles } from 'src/constants/generic-constants';
import { UserAuthContext } from 'src/models/AuthContextModels';
import { retrieveParameterFromSSM } from 'src/utils/ssm-parameter';

interface ApiEventLog {
  requestType: 'custom_query' | 'query' | 'mutation';
  requestName: string;
  requestStatus: 'API_SUCCESS' | 'API_FAILED';
  responseTime: number;
}

export let logger: KatalLogger;

/**
 * Validates the log level retrieved from SSM.
 * @param logLevel The log level to validate.
 * @returns The valid log level or Level.INFO if invalid.
 */
const validateLogLevel = (logLevel: string): Level => {
  if (Object.values(Level).includes(logLevel as Level)) {
    return logLevel as Level;
  }
  return Level.INFO;
};

export const getLogLevel = async (): Promise<Level> => {
  try {
    const params = '/xpt-fe/log-level';
    const result = await retrieveParameterFromSSM(params);
    return validateLogLevel(result);
  } catch (error) {
    return Level.INFO;
  }
};

export const configureKatalLogger = async (userAuthData: UserAuthContext) => {
  const logThreshold = await getLogLevel();
  const katalLoggerConfig: LoggerConfig = {
    url: 'https://logger.xpt.device.finance.amazon.dev/v1/log',
    logThreshold: logThreshold,
    maxLogLineSize: 10000,
    logToConsole: true,
    // IMPORTANT: Do not modify this structure as it is directly mapped to the log table.
    context: {
      app: 'xpt-website',
      env: 'prod',
      logType: 'general',
      userAlias: userAuthData.Alias,
      givenName: userAuthData.GivenName,
      userLDAPGroups: userAuthData.userLDAPGroups,
      isDev: userAuthData.isDev,
      isAdmin: userAuthData.isAdmin,
      isBusinessLeader: userAuthData.isBusinessLeader
    }
  };
  logger = new KatalLogger(katalLoggerConfig);
  logger.addPerformanceListener();
};

/**
 * Logs API events to AWS CloudWatch and records them in AWS RUM, including failures.
 * @param apiEvent - The API event log.
 * @param error - Optional. The error object captured when an API request fails.
 */
export const logApiEvent = (apiEvent: ApiEventLog, error?: any) => {
  const logPayload = {
    logType: 'api',
    apiEvent: {
      operation: apiEvent.requestType,
      operationName: apiEvent.requestName,
      requestStatus: apiEvent.requestStatus,
      responseTime: apiEvent.responseTime,
      ...(error && { failureDetails: error })
    }
  };

  // Log to AWS CloudWatch using the logger
  if (error) {
    logger.error(
      `API Request Summary: Request Type - ${apiEvent.requestType}, Request Name - ${apiEvent.requestName}, Response Time - ${apiEvent.responseTime}ms`,
      logPayload
    );
  } else {
    // logger.info(`API Request Summary: Request Type - ${apiEvent.requestType}, Request Name - ${apiEvent.requestName}, Response Time - ${apiEvent.responseTime}ms`, logPayload);
    logger.info(`Request Name - ${apiEvent.requestName}, Response Time - ${apiEvent.responseTime}ms`, logPayload);
  }

  // Record the event in AWS RUM
  recordAWSRumEvent(`${apiEvent.requestType}_${apiEvent.requestName}`, apiEvent.responseTime);
};

// IMPORTANT: Do not modify this structure as it is directly mapped to the log table.
export const entryLog = (pageId?: string, pageName?: string) => {
  logger.info(`User authenticated successfully!`, {
    logType: 'entry_log',
    pageId: pageId ? pageId : '',
    pageName: pageName ? pageName : SpecialMetricTitles.HomePage
  });
};
